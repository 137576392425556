import "./resetPassword.css";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useState} from "react";

const ResetPassword = () => {
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [pending, setPending] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	const navigate = useNavigate();

	const resetPassword = (event) => {
		event.preventDefault();
		setPending(true);

		if (password !== confirmPassword) {
			alert("Password and confirm password are not equal. Please try again.");
			return;
		}

		fetch("/api/reset-password", {
			method: "POST",
			body: JSON.stringify({
				password: password,
				code: searchParams.get("code")
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			setPending(false);
			// TODO: Add response messages instead of changing alert based on response code
			if (response.status === 200) {
				alert("Successfully reset password! Please try logging in with your new password.");
				navigate("/login");
				return;
			}

			response.text().then((text) => {
				alert(text);
			});
		});
	};

	return (
		<div className="resetPasswordContainer">
			<div className="resetPassword">
				<p className="formTitle">Reset Password</p>
				<form className="resetPasswordForm">
					<div>
						<label className="formLabel">New Password</label>
						<input className="formInput" type="password" value={password}
							   onChange={(e) => setPassword(e.target.value)}/>
					</div>

					<div>
						<label className="formLabel">Confirm New Password</label>
						<input className="formInput" type="password" value={confirmPassword}
							   onChange={(e) => setConfirmPassword(e.target.value)}/>
					</div>

					<p hidden={!pending}>Pending...</p>
					<button disabled={pending} className="resetPasswordSubmitButton" onClick={resetPassword}>Reset
						Password
					</button>
				</form>
			</div>
		</div>
	);
};

export default ResetPassword;