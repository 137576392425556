import "./confirmEmail.css";
import {useEffect, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import {useNavigate} from "react-router-dom";

const ConfirmEmail = () => {
	const {authed, unconfirmed} = useAuth();
	const [code, setCode] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		if (authed || !unconfirmed) navigate("/");
		if (unconfirmed) navigate("/confirm-email");

	}, [authed]);

	const confirmEmail = () => {
		fetch("/api/confirm-email", {
			method: "POST",
			body: JSON.stringify({
				code: code
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				window.alert("Successfully confirmed your email!");
				navigate("/login");
				window.location.reload();

			} else {
				alert("Error confirming email. Please check that you entered the confirmation code correctly, or try clicking the resend button in case your code has expired.");
			}
		});
	};

	const resendCode = () => {
		fetch("/api/resend-confirm", {
			method: "POST",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				alert("Resent code! Check your email for the new code.");

			} else if (response.status === 429) {
				window.alert("Resent too many codes. Wait a couple minutes and try again.");

			} else {
				alert("Error resending confirmation code!");
			}
		});
	};

	const logoutClick = () => {
		fetch("/api/logout", {
			method: "POST",
			headers: {
				"Content-type": "application/json; charset=UTF-8"
			}

		}).then((response) => {
			if (response.status === 200) {
				document.cookie = "login=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
				navigate('/');
				window.location.reload();
			}
		});
	}

	return (
		<div className="confirmEmailContainer">
			<div className="confirmForm">
				<p>A confirmation code has been sent to your email. Please check your email, and enter this code below.</p>
				<p>If you don't receive an email in the next minute or two, click the resend code button.</p>

				<p>Confirmation Code:</p>
				<input className="formInput"
					   type="text"
					   value={code}
					   onChange={(e) => setCode(e.target.value)}
					   required/>

				<div className="confirmButtons">
					<button className="lightButtonNoBorder" onClick={confirmEmail}>Confirm Email</button>
					<button className="lightButtonNoBorder" onClick={resendCode}>Resend Confirmation Code</button>
					<button className="lightButtonNoBorder" onClick={logoutClick}>Logout</button>
				</div>
			</div>
		</div>
	);
}

export default ConfirmEmail;