import Navbar from "../navbar/navbar";
import React from "react";

import "./home.css";
import {useNavigate} from "react-router-dom";
import Logo from "../logo/logo";

const Home = () => {
	const navigate = useNavigate();

	return (
		<div className="homeContainer">
			<div className="navbar">
				<Navbar hideExtras={false}/>
			</div>

			<div className="title">
				<p className="titleText">SUITED</p>
				<p className="descriptionText">THE ULTIMATE POKER BANKROLL APPLICATION</p>
				<button className="descriptionSignupButton" onClick={() => navigate("/signup")}>SIGN UP</button>
			</div>

			<div className="mobilePreview">
				<img className="mobileImage" src="/SuitedPhone.webp" alt="Mobile demonstration"/>
			</div>

			<div className="featuresContainer">
				<div className="featureContainer graphFeatureContainer">
					<div className="descriptionContainer">
						<p className="featureTitle">Profit Graph</p>
						<p className="featureDescription">Experience a complete overview of your poker journey with our
							profit
							graph. This dynamic line graph will show your total profit over time, giving you a clear
							view of
							your performance. Watch how a win or a loss impacts your progress, and easily identify
							trends or
							patterns in your gameplay. Our profit graph will help you refine your strategy, whether
							you're
							celebrating a winning streak or analyzing a dip.</p>
					</div>
					<img className="featureImage" src="/graph.png" alt="Graph demonstration"/>
				</div>

				<div className="featureContainer tableFeatureContainer">
					<div className="descriptionContainer">
						<p className="featureTitle">Game History Table</p>
						<p className="featureDescription">Analyze past results with our detailed game history table.
							This
							feature allows viewing of every game you've ever played, along with statistics for each
							session.
							Easily navigate your history with our advanced filtering options, allowing you to sort by
							date,
							type, blinds, or custom tags, which update the table and profit graph in real time for
							further
							study. Whether you're looking for a specific session or a broad analysis of your performance
							across different game types, the game history table can provide in-depth analysis and
							continuous
							improvement for your game.</p>
					</div>
					<img className="featureImage" src="/table.png" alt="Table demonstration"/>
				</div>

				<div className="featureContainer toolsFeatureContainer">
					<div className="descriptionContainer">
						<p className="featureTitle">Tools</p>
						<p className="featureDescription">Enhance your home games with our tools suite, which includes a
							seat shuffler, chip value counter, equity calculator, and more. Let our tools get to work
							managing your next tournament or cash game, while you focus on the fun.</p>
					</div>
					<img className="featureImage" src="/tools.png" alt="Tools demonstration"/>
				</div>

				<div className="featureContainer leaguesFeatureContainer">
					<div className="descriptionContainer">
						<p className="featureTitle">Leagues</p>
						<p className="featureDescription">Get competitive with our leagues feature. Create or join a
							league
							with other players to compete for leaderboard positions across multiple different
							categories.
							Compare statistics with other players in your league and push each other to improve with our
							detailed statistics section, providing insight into other players performance at a
							glance. Whether you're a casual player or seasoned professional, leagues add an element of
							excitement to your poker experience, turning every game into a competitive and social
							event.</p>
					</div>
					<img className="featureImage" src="/leagues.png" alt="Leagues demonstration"/>
				</div>
			</div>
		</div>
	);
};

export default Home;