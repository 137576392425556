import "./pricing.css";
import Navbar from "../navbar/navbar";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "../../hooks/useAuth";

const Pricing = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [prices, setPrices] = useState([]);
	const [proPrice, setProPrice] = useState([]);
	const [pending, setPending] = useState(false);
	const {authed, pro} = useAuth();

	useEffect(() => {
		setPending(true);
		const fetchPrices = async () => {
			const {prices} = await fetch("/api/stripe/config").then(r => r.json());
			setPrices(prices);

			setProPrice(prices.find((a) => a.lookup_key === "pro_monthly"));
		};
		fetchPrices();

		setPending(false);
	}, []);


	const createSubscription = async (priceId) => {
		setPending(true);

		if (!authed) {
			navigate("/login");
			return;
		}

		const {subscriptionId, clientSecret} = await fetch("/api/stripe/create-subscription", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				priceId
			}),

		}).then(r => r.json());

		navigate("/checkout", {
			state: {
				from: location,
				subscriptionId,
				clientSecret,
			},
			replace: false
		});

		setPending(false);
	};

	return (
		<div className="pricingContainer">
			<div className="navbar">
				<Navbar hideExtras={false}/>
			</div>

			<div className="planCard free">
				<p className="planTitle">FREE</p>

				<p className="planPrice">$0 / Month</p>

				<ul className="planFeatures">
					<li>Dashboard - Everything you need in a poker tracker, all in one place. Track your profits on
						a price graph, show some basic statistics, and view your game history.
					</li>
				</ul>
			</div>

			<div className="planCard pro">
				<p className="planTitle">PRO</p>

				<p className="planPrice">${proPrice && proPrice.unit_amount / 100} / Month</p>

				<ul className="planFeatures">
					<li>Everything in the free plan plus...</li>
					<li>Analytics - View advanced statistics and graphs to further understand your gameplay. Find
						trends and habits quickly, and adjust your playstyle to increase your profits.
					</li>
					<li>Tools - All the tools you'll need to run a home game. Shuffle seat positions, find the
						perfect chip denominations, and calculate equity on the fly with our tools suite.
					</li>
					<li>Leagues - Compete against other players, compare statistics, and more through
						leagues. Add a competitive component to your game by ranking yourself on leaderboards with
						your friends!
					</li>
				</ul>

				{
					pro ? <p>You already have a PRO subscription.</p> :
						<button className="upgradeButton lightButtonNoBorder"
								onClick={() => createSubscription(proPrice.id)}
								disabled={pending}>Upgrade</button>
				}
			</div>
		</div>
	);
};

export default Pricing;