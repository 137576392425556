import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import './App.css';
import Layout from "./components/layout/layout";
import Dashboard from "./components/dashboard/dashboard";
import NotFound from "./components/not-found/notFound";
import Signup from "./components/signup/signup";
import Login from "./components/login/login";
import Tools from "./components/tools/tools";
import Leagues from "./components/leagues/leagues";
import Home from "./components/home/home";
import Analytics from "./components/analytics/analytics";
import Profile from "./components/profile/profile";
import About from "./components/about/about";
import ProtectedRoute from "./components/protected-route/protectedRoute";
import {useAuth} from "./hooks/useAuth";
import Pricing from "./components/pricing/pricing";
import Checkout from "./components/checkout/checkout";
import Settings from "./components/settings/settings";
import ConfirmEmail from "./components/confirm-email/confirmEmail";
import ForgotPassword from "./components/forgot-password/forgotPassword";
import ResetPassword from "./components/reset-password/resetPassword";
import {useEffect} from "react";
import ReactGA from "react-ga4";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

function App() {
	const {loading} = useAuth();

	useEffect(() => {
		const GA_ID = "G-L4WFBPY46C";
		ReactGA.initialize(GA_ID);
		ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.search });
	}, []);

	return (
		<div className="App">
			{loading ?
				// TODO: Loading page
				<div className="loadingDiv">Loading...</div>
				: (
					<BrowserRouter>
						<Routes>
							<Route path="/index.html" element={<Navigate to="/"/>}/>
							<Route path="/" element={<Layout/>}>
								<Route index element={<ProtectedRoute><Home/></ProtectedRoute>}/>
								<Route path="/signup" element={<ProtectedRoute><Signup/></ProtectedRoute>}/>
								<Route path="/login" element={<ProtectedRoute><Login/></ProtectedRoute>}/>
								<Route path="/confirm-email" element={<ConfirmEmail/>}/>
								<Route path="/forgot-password" element={<ForgotPassword/>}/>
								<Route path="/reset-password" element={<ResetPassword/>}/>

								<Route path="/profile/*" element={<ProtectedRoute needsAuth={true}><Profile/></ProtectedRoute>}/>
								<Route path="/dashboard" element={<ProtectedRoute needsAuth={true}><Dashboard/></ProtectedRoute>}/>
								<Route path="/tools" element={<ProtectedRoute needsAuth={true} needsPro={true}><Tools/></ProtectedRoute>}/>
								<Route path="/leagues" element={<ProtectedRoute needsAuth={true} needsPro={true}><Leagues/></ProtectedRoute>}/>
								<Route path="/analytics" element={<ProtectedRoute needsAuth={true} needsPro={true}><Analytics/></ProtectedRoute>}/>
								<Route path="/about" element={<About/>}/>
								<Route path="/pricing" element={<Pricing/>}/>

								<Route path="/checkout" element={
									fetch("/api/stripe/config")
										.then((response) => response.json())
										.then((data) => {
											const stripePromise = loadStripe(data.publishableKey);
												return <Elements stripe={stripePromise}>
													<ProtectedRoute needsAuth={true}>
														<Checkout/>
													</ProtectedRoute>
												</Elements>
										})}/>
								<Route path="/settings" element={<ProtectedRoute needsAuth={true}><Settings/></ProtectedRoute>}/>
								<Route path="*" element={<NotFound/>}/>
							</Route>
						</Routes>
					</BrowserRouter>
				)}
		</div>
	);
}

export default App;
