export async function getLoggedInUsername() {
	let response = await fetch("/api/account", {
		method: "GET",
		headers: {
			"Content-type": "application/json; charset=UTF-8",
		}

	});

	if (response.status === 200) {
		let json = await response.json();
		return json.username;
	}

	return null;
}

export async function getAccountInfo(username) {
	let response = await fetch("/api/account/" + username, {
		method: "GET",
		headers: {
			"Content-type": "application/json; charset=UTF-8",
		}

	});

	if (response.status === 200) {
		return await response.json();
	}
}

export async function getAccountCreatedDate(username) {
	let response = await fetch("/api/account/" + username, {
		method: "GET",
		headers: {
			"Content-type": "application/json; charset=UTF-8",
		}
	});

	if (response.status === 200) {
		let json = await response.json();
		return json.created.$date;
	}

	return null;
}

export async function addFriend(username) {
	let response = await fetch("/api/add-friend", {
		method: "POST",
		body: JSON.stringify({
			username: username
		}),
		headers: {
			"Content-type": "application/json; charset=UTF-8"
		}
	});

	if (response.status === 200) {
		alert("Successfully sent friend request to " + username);
	}
}

export async function removeFriend(username) {
	let res = window.confirm("Are you sure you want to remove " + username + " as a friend?");

	if (!res) return;

	let response = await fetch("/api/remove-friend", {
		method: "POST",
		body: JSON.stringify({
			username: username
		}),
		headers: {
			"Content-type": "application/json; charset=UTF-8"
		}
	});

	if (response.status === 200) {
		alert("Successfully removed " + username + "!");
		window.location.reload();
	}
}

export async function getFriends(username) {
	let response = await fetch(`/api/friends/${username}`, {
		method: "GET",
		headers: {
			"Content-type": "application/json; charset=UTF-8"
		}
	});

	if (response.status === 200) {
		return await response.json();
	}
}

export async function getOutgoingFriendRequests() {
	let response = await fetch("/api/friend-requests-out", {
		method: "GET",
		headers: {
			"Content-type": "application/json; charset=UTF-8",
		}
	});

	if (response.status === 200) {
		return await response.json();
	}
}

export async function getIncomingFriendRequests() {
	let response = await fetch("/api/friend-requests", {
		method: "GET",
		headers: {
			"Content-type": "application/json; charset=UTF-8",
		}
	});

	if (response.status === 200) {
		return await response.json();
	}
}

export async function cancelOutgoingFriendRequest(username) {
	let response = await fetch("/api/cancel-friend-request", {
		method: "DELETE",
		headers: {
			"Content-type": "application/json; charset=UTF-8"
		},
		body: JSON.stringify({
			username: username
		})
	});

	if (response.status === 200) {
		alert("Successfully cancelled friend request to " + username + "!");
	}
}

export async function getMyGameHistory() {
	let response = await fetch("/api/game-history", {
		method: "GET",
		headers: {
			"Content-type": "application/json; charset=UTF-8",
		}

	});

	if (response.status === 200) {
		let json = await response.json();
		return json.history;
	}

	return null;
}

export async function getGameHistory(username) {
	let response = await fetch(`/api/game-history/${username}`, {
		method: "GET",
		headers: {
			"Content-type": "application/json; charset=UTF-8",
		}
	});

	if (response.status === 200) {
		let json = await response.json();
		return json.history;
	}

	return null;
}