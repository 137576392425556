import React from "react";
import {Navigate, Route} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";

const ProtectedRoute = ({needsAuth, needsPro, children}) => {
	const {authed, unconfirmed, pro} = useAuth();

	if (needsPro && !pro) {
		return <Navigate to="/pricing"/>;
	}

	if (unconfirmed) {
		return <Navigate to="/confirm-email"/>
	}

	if (needsAuth && !authed) {
		return <Navigate to="/login"/>
	}

	return children;
};

export default ProtectedRoute;