import React, {useEffect, useState} from "react";

import "./leagues.css";
import {calculateAccuracy, calculateHistoryStats, formatCurrency} from "../utils";
import ColoredStatistic from "../colored-statistic/coloredStatistic";
import Modal from "../modal/modal";
import CreateLeagueForm from "../forms/create-league-form/createLeagueForm";
import PlayerSearchBar from "../player-searchbar/playerSearchBar";
import {useNavigate} from "react-router-dom";
import Page from "../page/page";
import LeaderboardEntry from "../leaderboard-entry/leaderboardEntry";
import {getLoggedInUsername} from "../api";

const Leagues = () => {
	const [leagueNames, setLeagueNames] = useState([]);
	const [selectedLeague, setSelectedLeague] = useState("None");
	const [category, setCategory] = useState("");
	const [statsState, setStatsState] = useState([]);
	const [isLeagueOwner, setIsLeagueOwner] = useState(false);
	const [loggedInUsername, setLoggedInUsername] = useState("");

	const [showCreateLeagueModal, setShowCreateLeagueModal] = useState(false);
	const [showManagePlayersModal, setShowManagePlayersModal] = useState(false);
	const [showInvitePlayerModal, setShowInvitePlayerModal] = useState(false);
	const [showPlayerStatsModal, setShowPlayerStatsModal] = useState(false);

	const [potentialInvite, setPotentialInvite] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		getLoggedInUsername().then((name) => {
			setLoggedInUsername(name);
		});
	}, []);

	useEffect(() => {
		getUserLeagues();
		setCategory("totalProfit");
	}, [loggedInUsername]);

	function setStats(leagueName) {
		fetch("/api/league/" + leagueName, {
			method: "GET",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				response.json().then((leagueJson) => {
					setIsLeagueOwner(leagueJson.owner === loggedInUsername);
					let statsArr = [];

					for (let i = 0; i < leagueJson.players.length; i++) {
						let username = leagueJson.players[i];

						fetch("/api/game-history/" + username, {
							method: "GET",
							headers: {
								"Content-type": "application/json; charset=UTF-8",
							}

						}).then((response) => {
							if (response.status === 200) {
								response.json().then((historyJson) => {
									statsArr.push({
										username: username,
										history: historyJson.history,
										stats: calculateHistoryStats(historyJson.history)
									});

									if (statsArr.length === leagueJson.players.length) { // TODO: This is so janky
										setStatsState(statsArr);
									}
								});
							}
						});
					}
				});
			}
		});
	}

	function getUserLeagues() {
		fetch("/api/leagues", {
			method: "GET",
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				response.json().then((json) => {
					setLeagueNames(json);

					if (json.length > 0) { // Auto-selecting first available league
						setStats(json[0]);
						setSelectedLeague(json[0]);
					}
				});
			}
		});
	}

	const handleResultClick = (result) => {
		setPotentialInvite(result);
	}

	const sendInvite = () => {
		fetch("/api/league/invite", {
			method: "POST",
			body: JSON.stringify({
				league_name: selectedLeague,
				username: potentialInvite
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				alert("Successfully invited " + potentialInvite + " to the league!");
				return;
			}

			response.json().then((json) => {
				let respMsg = "";
				json.messages.forEach((msg) => respMsg += msg + "\n");
				alert(respMsg);
			});
		});
	}

	const removePlayer = (player) => {
		const conf = window.confirm("Are you sure you want to remove " + player.username + " from " + selectedLeague + "?");
		if (!conf) return;

		fetch("/api/league/remove-player", {
			method: "POST",
			body: JSON.stringify({
				league_name: selectedLeague,
				username: player.username
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				alert("Successfully removed " + player.username + " from the league!");
			}
		});
	}

	const leaveLeague = () => {
		const conf = window.confirm("Are you sure you want to leave " + selectedLeague + "? If you are the owner, a new owner will randomly be assigned.");
		if (!conf) return;

		fetch("/api/league/leave", {
			method: "POST",
			body: JSON.stringify({
				league_name: selectedLeague,
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				alert("Successfully left " + selectedLeague + "!");
				window.location.reload();
			}
		});
	}

	const makeOwner = (username) => {
		const conf = window.confirm("Are you sure you want to make " + username + " the owner of " + selectedLeague + "? You cannot undo this.");
		if (!conf) return;

		fetch("/api/league/transfer-ownership", {
			method: "POST",
			body: JSON.stringify({
				league_name: selectedLeague,
				new_owner: username
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			if (response.status === 200) {
				alert("Successfully transferred ownership of  " + selectedLeague + " to " + username + "!");
				window.location.reload();
			}
		});
	};

	return (
		<Page>
			<div className="leaguesContainer">
				<div className="leagueName">
					<p>{selectedLeague}</p>
				</div>

				<div className="leagueSelection">
					<p className="sectionHeader">Select League</p>
					<div className="leagueSelectionContainer">
						{
							leagueNames.map((result) => (
								<p className="leagueNameItem" onClick={() => {
									setStats(result);
									setSelectedLeague(result);
								}}>{result}</p>
							))
						}
					</div>
					<button className="lightButtonNoBorder" onClick={() => setShowCreateLeagueModal(true)}>Create
					</button>

					<Modal stopScroll={true} show={showCreateLeagueModal} handleClose={() => setShowCreateLeagueModal(false)}>
						<div>
							<p className="sectionHeader">Create League</p>
							<div className="createLeagueContainer">
								<CreateLeagueForm/>
							</div>
						</div>
					</Modal>
				</div>

				<div className="leagueManagement">
					<button className="lightButtonNoBorder" onClick={() => setShowPlayerStatsModal(true)}>View Players
					</button>
					<Modal className="playerStatsModal" show={showPlayerStatsModal}
						   handleClose={() => setShowPlayerStatsModal(false)}>
						<table className="playerStatsTable">
							<thead>
							<tr>
								<th>Player</th>
								<th>Total Profit</th>
								<th>Avg Profit</th>
								<th>Avg Profit w/o Outliers</th>
								<th>Consistency</th>
								<th>Biggest Win</th>
								<th>Biggest Loss</th>
								<th>Win Rate</th>
								<th>Entries</th>
								<th>Accuracy</th>
							</tr>
							</thead>
							<tbody>
							{
								statsState.map((row) => (
									<tr>
										<td>{row.username}</td>
										<td><ColoredStatistic value={formatCurrency(row.stats.cumulativeTotal)}
															  isGreen={row.stats.cumulativeTotal >= 0}/></td>
										<td><ColoredStatistic value={formatCurrency(row.stats.averagePl)}
															  isGreen={row.stats.averagePl >= 0}/></td>
										<td><ColoredStatistic value={formatCurrency(row.stats.trimmedMean)}
															  isGreen={row.stats.trimmedMean >= 0}/></td>
										<td>{(1000 / row.stats.variance).toFixed(2)}</td>
										<td><ColoredStatistic value={formatCurrency(row.stats.maxPl)}
															  isGreen={row.stats.maxPl >= 0}/></td>
										<td><ColoredStatistic value={formatCurrency(row.stats.minPl)}
															  isGreen={row.stats.minPl >= 0}/></td>
										<td><ColoredStatistic
											value={`${((row.stats.winRate) * 100).toFixed(2)}%`}
											isGreen={row.stats.winRate >= 0.5}/></td>
										<td>{row.stats.numGames}</td>
										<td>{(calculateAccuracy(row.history, row.stats.cumulativeTotal, row.stats.numGames, row.stats.trimmedMean) * 100).toFixed(2) + "%"}</td>
									</tr>
								))
							}
							</tbody>
						</table>
					</Modal>
					<button className="lightButtonNoBorder" onClick={() => leaveLeague()}>Leave League</button>

					{
						isLeagueOwner ?
							<>
								<button className="lightButtonNoBorder"
										onClick={() => setShowManagePlayersModal(true)}>Manage Players
								</button>
								<Modal show={showManagePlayersModal}
									   handleClose={() => setShowManagePlayersModal(false)}>
									<div>
										<p className="sectionHeader">Manage Players</p>
										<div className="managePlayersContainer">
											{
												statsState.map((result) => {
													if (result.username === loggedInUsername) return;
													return (
														<div className="leagueUserManagement">
															<p>{result.username}</p>
															<button onClick={() => removePlayer(result)}>Remove</button>
															<button onClick={() => makeOwner(result.username)}>Make
																Owner
															</button>
														</div>
													);
												})
											}
										</div>
									</div>
								</Modal>

								<button className="lightButtonNoBorder"
										onClick={() => setShowInvitePlayerModal(true)}>Invite Player
								</button>
								<Modal
									stopScroll={true}
									show={showInvitePlayerModal}
									handleClose={() => setShowInvitePlayerModal(false)}>
									<PlayerSearchBar handleResultClick={handleResultClick}/>
									{
										potentialInvite !== "" &&
										<button onClick={sendInvite}>Invite {potentialInvite}</button>
									}
								</Modal>
							</>
							: ""
					}
				</div>

				<div className="leaderboard">
					<div className="leaderboardTable">
						<p className="leaderboardCategory">{category === "totalProfit" ? "Total Profit" :
							category === "biggestWin" ? "Biggest Win" :
								category === "biggestLoss" ? "Biggest Loss" :
									category === "highestAvg" ? "Average Profit" :
										category === "mostConsistent" ? "Consistency" : "None"}</p>

						<select onChange={e => setCategory(e.target.value)}>
							<option value="totalProfit">Total Profit</option>
							<option value="biggestWin">Biggest Win</option>
							<option value="biggestLoss">Biggest Loss</option>
							<option value="highestAvg">Highest Average Profit</option>
							<option value="mostConsistent">Most Consistent</option>
						</select>

						<div className="leaderboardTableEntries">
							{
								statsState.toSorted((a, b) => (
									category === "totalProfit" ?
										b.stats.cumulativeTotal - a.stats.cumulativeTotal
										: category === "biggestWin" ?
											b.stats.maxPl - a.stats.maxPl
											: category === "biggestLoss" ?
												a.stats.minPl - b.stats.minPl
												: category === "highestAvg" ?
													b.stats.averagePl - a.stats.averagePl
													: category === "mostConsistent" ?
														(1000 / b.stats.variance) - (1000 / a.stats.variance)
														: 1

								)).map((row, index, sorted) => (
									<LeaderboardEntry minStat={sorted[sorted.length - 1].stats}
													  maxStat={sorted[0].stats}
													  category={category}
													  row={row}
													  index={index}/>
								))
							}
						</div>
					</div>
				</div>
			</div>
		</Page>
	)
}

export default Leagues;