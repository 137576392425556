import "./forgotPassword.css";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const ForgotPassword = () => {
	const [email, setEmail] = useState("");
	const [pending, setPending] = useState(false);

	const navigate = useNavigate();

	const sendLink = (event) => {
		event.preventDefault();
		setPending(true);

		fetch("/api/forgot-password", {
			method: "POST",
			body: JSON.stringify({
				email: email
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			}

		}).then((response) => {
			setPending(false);
			// TODO: Add response messages instead of changing alert based on response code
			if (response.status === 200) {
				alert("Successfully sent reset password link! Please check your email. If you do not receive a link after 5 minutes, press the reset button again.");
				navigate("/login");
				return;
			}

			response.text().then((text) => {
				alert(text);
			});
		});
	};

	return (
		<div className="forgotPasswordContainer">
			<div className="forgotPassword">
				<p className="formTitle">Reset Password</p>
				<form className="forgotPasswordForm">
					<div>
						<label className="formLabel">Email</label>
						<input className="formInput" type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
					</div>

					<p hidden={!pending}>Pending...</p>
					<button disabled={pending} className="forgotPasswordSubmitButton" onClick={sendLink}>Send Reset Password Link</button>
				</form>
			</div>
		</div>
	);
};

export default ForgotPassword;